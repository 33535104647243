<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="924px"
    title="发票信息">
    <el-form
      :model="params"
      :rules="rules"
      ref="form"
      class="t-form"
      label-width="84px"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="开票名称" prop="invoiceType">
        <el-radio-group v-model="params.invoiceType">
          <el-radio :label="1">普票</el-radio>
          <el-radio :label="2">专票</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="double-cell">
        <el-form-item label="公司抬头" class="mr52" prop="invoiceCompanyName">
          <el-input v-model="params.invoiceCompanyName" placeholder="请填写公司抬头"/>
        </el-form-item>
        <el-form-item label="开票号码" prop="invoiceNo">
          <el-input v-model="params.invoiceNo" placeholder="请填写开票号码"/>
        </el-form-item>
      </div>
      <div class="double-cell">
        <el-form-item label="手机号码" class="mr52" prop="invoicePhone">
          <el-input
            v-model="params.invoicePhone"
            maxlength="11"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="请填写手机号码"/>
        </el-form-item>
        <el-form-item label="接收邮箱" prop="invoiceEmail">
          <el-input v-model="params.invoiceEmail" placeholder="请填写接收邮箱"/>
        </el-form-item>
      </div>
      <el-form-item label="邮寄地址" prop="invoiceAddress">
        <el-input v-model="params.invoiceAddress" placeholder="请填写邮寄地址" />
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
      <t-btn @click="visible = false" class="bold" isText>取消</t-btn>
      <t-btn
        @click="commit"
        w="78px"
        h="36px"
        class="bold ml30">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
import { isMobile, isEmail } from '@/assets/javascript/util'

export default {
  data() {
    const invoicePhone = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    const invoiceEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      params: {
        invoiceType: '',
        invoiceCompanyName: '',
        invoiceNo: '',
        invoicePhone: '',
        invoiceEmail: '',
        invoiceAddress: ''
      },
      rules: {
        invoiceType: [
          { required: true, message: '请选择开票名称' },
        ],
        invoiceCompanyName: [
          { required: true, message: '请填写开票公司抬头' },
        ],
        invoiceNo: [
          { required: true, message: '请填写开票号码' },
        ],
        invoicePhone: [
          { required: true, message: '请填写开票手机号' },
          { validator: invoicePhone }
        ],
        invoiceEmail: [
          { required: true, message: '请填写开票接收邮箱' },
          { validator: invoiceEmail }
        ],
        invoiceAddress: [
          { required: true, message: '请填写发票邮寄地址' },
        ],
      },
      id: null
    }
  },
  methods: {
    // 确认
    commit() {
      const { params, id } = this
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('commit', { ...params, id })
        }
      })
    },
    // 显示
    show(id) {
      this.id = id
      this.visible = true
    },
    close() {
      this.visible = false
    },
    // 关闭重置
    closed() {
      this.$refs.form.resetFields()
      this.id = null
    }
  }
}
</script>
