<template>
  <div class="order-container">
    <t-pagination
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :pages="pages">
      <el-table
        :data="list"
        slot="table"
        class="t-tabel"
        style="width: 100%"
        border>
        <el-table-column
          label="编号"
          width="80">
          <template slot-scope="scope">
            <span>{{ (scope.$index + 1) + (pageParams.page - 1) * pageParams.perPage }}</span>  
          </template>
        </el-table-column>
        <el-table-column
          v-for="(v, i) in labelList"
          :key="i"
          :label="v.label"
          :min-width="v.width">
          <template slot-scope="scope">
            <span v-if="v.key === 'invoice'">{{ scope.row[v.key] ? '是' : '否' }}</span>
            <span v-else>{{ scope.row[v.key] || NOT }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="curGroupId!==2"
          label="操作"
          width="156">
          <template slot-scope="scope">
            <t-btn @click="browseInvoice(scope.row)" v-if="scope.row.invoice" isText>查看开票信息</t-btn>
            <t-btn @click="fillInvoice(scope.row.id)" v-else isText>填写开票信息</t-btn>
          </template>
        </el-table-column>
      </el-table>
    </t-pagination>

    <!-- 填写开票 -->
    <invoice-fill @commit="fillCommit" ref="invoiceFill" />
    <invoice-detail ref="invoiceDetail" />
  </div>
</template>

<script>
import pagination from '@/mixins/pagination'
import message from '@/mixins/message'
import InvoiceFill from './components/invoice-fill'
import InvoiceDetail from './components/invoice-detail'

export default {
  mixins: [pagination, message],
  components: {
    InvoiceFill,
    InvoiceDetail
  },
  data() {
    return {
      labelList: [
        {
          label: '订单人数',
          width: 140,
          key: 'userCount'
        }, {
          label: '订单金额 (元)',
          width: 172,
          key: 'amount'
        }, {
          label: '订单时间',
          width: 152,
          key: 'createdAt'
        }, {
          label: '订单类型',
          width: 140,
          // key: 'zh_type'
          key : 'typeText',
        }, {
          label: '是否已开票',
          width: 156,
          key: 'invoice'
        }, {
          label: '发票邮件号',
          width: 160,
          key: 'invoiceEmailNo'
        }
      ]
    }
  },
  methods: {
    init() {
      this.getList()
    },
    // 填写发票信息
    fillInvoice(id) {
      this.$refs.invoiceFill.show(id)
    },
    // 查看发票信息
    browseInvoice(val) {
      this.$refs.invoiceDetail.show(val)
    },
    // 获取订单
    getList() {
      const { pageParams } = this

      this.$api.order.getOrderList({
        ...pageParams
      }).then(res => {
        this.list = res.items || []
        this.pages = res.page || null
      })
    },
    // 填写发票回调
    fillCommit(params) {
      this.$api.order.fillInvoice(params).then(res => {
        this.success({
          message: '提交发票信息成功'
        })
        this.getList()
        this.$refs.invoiceFill.close()
      })
    },
    // getTypeText(type){
    //   return ["体验","开通","续费"][type-1]
    // }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
  .order-container {
    padding: 30px 40px;
  }
</style>