<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="924px"
    title="发票信息">
    <el-form
      class="t-form"
      label-width="84px"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="开票名称">
        <el-radio-group :value="params.invoiceType">
          <el-radio :label="1">普票</el-radio>
          <el-radio :label="2">专票</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="double-cell">
        <el-form-item label="公司抬头" class="mr52">
          <el-input v-model="params.invoiceCompanyName" readonly/>
        </el-form-item>
        <el-form-item label="开票号码">
          <el-input v-model="params.invoiceNo" readonly/>
        </el-form-item>
      </div>
      <div class="double-cell">
        <el-form-item label="手机号码" class="mr52">
          <el-input v-model="params.invoicePhone" readonly/>
        </el-form-item>
        <el-form-item label="接收邮箱">
          <el-input v-model="params.invoiceEmail" readonly/>
        </el-form-item>
      </div>
      <el-form-item label="邮寄地址">
        <el-input v-model="params.invoiceAddress" readonly/>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { isMobile, isEmail } from '@/assets/javascript/util'
const initParams = () => ( {
  invoiceType: '',
  invoiceCompanyName: '',
  invoiceNo: '',
  invoicePhone: '',
  invoiceEmail: '',
  invoiceAddress: ''
})

export default {
  data() {
    return {
      visible: false,
      params: initParams(),
    }
  },
  methods: {
    // 显示
    show(val) {
      Object.keys(this.params).forEach(v => {
        this.params[v] = val[v]
      })
      this.visible = true
    },
    close() {
      this.visible = false
    },
    // 关闭重置
    closed() {
      this.params = initParams()
    }
  }
}
</script>
